import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";

const SetUpAnAccount = () => {
    const seo = {
        metaDesc: 'Set up an account with the USPTO - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="Set up an account with the USPTO - Inventiv.org" canonical='/provisionalbuilder' seo={seo} />
            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <h1>1. How to set up an account with the USPTO? </h1>
                    <div>
                        <p><strong>Table of Contents</strong></p>
                        <p><strong>1.1&nbsp;&nbsp;&nbsp; <a href="#gettingstarted" class="d-clr">Getting Started – New Users</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.1.1&nbsp; <a href="#usptoaccount" class="d-clr" >Create a USPTO.gov account</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.1.2&nbsp; <a href="#customernumber" class="d-clr">Obtain a Customer Number</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.1.3&nbsp; <a href="#verified" class="d-clr">Obtain a Verified USPTO.gov Account</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.1.4&nbsp; <a href="#update" class="d-clr">Update an existing Customer Number</a></strong></p>
                        <p><strong>1.2&nbsp;&nbsp;&nbsp; <a href="#keyconsiderations" class="d-clr">USPTO Registration Modernizing – key considerations</a></strong></p>
                        <p><strong>1.3&nbsp;&nbsp;&nbsp; <a href="#filingavailable" class="d-clr">Filing types available at USPTO.gov Account</a></strong></p>
                    </div>
                    <h2 id="gettingstarted">3.1 Getting Started – New Users</h2>
                    <p>For secure two-factor authentication to USPTO.gov account, the Patent Electronic System uses a single sign-on (SSO) system. It requires an email address as your account name or user ID. With the authentication you will get a one-time confirmation code by email or cell phone to allow access to the Patent Electronic Systems.<br /> Unregistered user, can only file applications and access the available public applications but won’t be able to follow up on the status of their pending application unless they register.</p>
                    <p>The registered user has range of benefits from reviewing the status of their unpublished application online, having the ability to save created materials that will be need for the final application, to linking it with their customer number.</p>
                    <p>Consequently, we profoundly urge users to register with USPTO.gov system for the benefits it offers. Clients may get “registered” status in the following steps:<br /> 1. Create a USPTO.gov account<br /> 2. Create a Customer Number<br /> 3. Submit the Patent Electronic Verification Form to register your USPTO.gov account and link your customer number to access and use the Patent Electronic Systems.</p>
                    <h3 id="usptoaccount">1.1.1 Create a USPTO.gov account</h3>
                    <p>MYUSPTO homepage allow users to create and oversee their accounts. On the page one can create an account, change password, input personal information such as email addresses and phone number and enable a two-steps authentication. This data is utilized to particularly recognize you and enable your patent information security.<br /> When creating an USPTO.gov account, you will need to register with your legal names: first, middle and last name and use them to verify on Patent Electronic Verification Form. This is to avoid the confusion between people with similar names.</p>
                    <ul><li>&nbsp;For the registered practitioners the name provided must correspond to Office of Enrollment and Discipline records.</li><li>&nbsp;When creating the USPTO.gov account, you must opt in two-step authentication to access EFS-Web and Private PAIR</li></ul>
                    <p>To guarantee the security of your information, follow the policy described in the Patent Electronic System Access Document and Patent Electronic System Subscriber Agreement.</p>
                    <h3 id="customernumber">1.1.2 Obtain a Customer Number</h3>
                    <p>A Customer Number is a unique number created by the USPTO and is used in lieu of a physical address. Linking your customer number to USPTO.gov account ensure you can access patent application information such as status. Customer number also allows combining all your fillings into a single mailing address.<br /> If you are a registered attorney or patent agent, or a person granted limited recognition, then your registration number or limited recognition number must be linked with your Customer Number. The linking is achieved by adding your registration number to the Customer Number Request form.</p>
                    <h3 id="verified">1.1.3 Obtain a Verified USPTO.gov Account</h3>
                    <p>After obtaining a Customer Number, having created an USPTO.gov account and read all the terms and condition in the Patent Electronic System Access Document and Patent Electronic System Subscriber Agreement, you can download and complete the Patent Electronic System Verification Form.<br /> The name listed on the USPTO.gov account and on the Patent Electronic Verification form must be similar to reduce form processing period.</p>
                    <h3 id="update">1.1.4 Update an existing Customer Number</h3>
                    <p>You can update any existing customer number If you are update a registered Private PAIR user, simply by accessing real time first authenticating, then selecting PAIR Administration, then View Customer Number Details.<br /> Alternatively, you can update the information of an existing customer number by downloading then faxing the completed document through:<br /> Mail Stop EBC Customer Number<br /> Commissioner for Patents<br /> P.O. Box 1450<br /> Alexandria, VA 22313-1450<br /> One can additionally file a change of correspondence address form PTO/AIA/122 or PTO/SB122 to an individual application from a correspondence address to a customer number.</p>
                    <h2 id="keyconsiderations">1.2 USPTO Registration Modernizing – key considerations</h2>
                    <p>• Enhance consistency and support.<br /> • Provide an integrated and intuitive interface.<br /> • Refine to the user satisfaction.<br /> • Prepare and integrate data with other USPTO systems.</p>
                    <h2 id="filingavailable">1.3 Filing types available at USPTO.gov Account</h2>
                    <p><strong>• Utility</strong><br /> – Nonprovisional (also in DOCX)<br /> – Provisional (also in DOCX)<br /> – U.S. National Stage filed under 35 USC 371 (also in<br /> DOCX)<br /> <strong>• Design</strong><br /> – Nonprovisional<br /> <strong>• International</strong><br /> – International PCT application<br /> <strong>• Patent initiatives</strong><br /> – Track one prioritized examination<br /> – Accelerated examination – utility<br /> – Accelerated examination – design<br /> <strong>• Reissue</strong><br /> – Request for reissue<br /> <strong>• Reexam</strong><br /> – Request for reexam – by patent owner<br /> – Request for reexam – by third party<br /> • Follow-on document types<br /> – Document/fees for an existing application**<br /> – eTerminal disclaimer<br /> – Web 85b – issue fee transmittal<br /> <strong>• Petition to make special based on age</strong><br /> <strong>• Petition to withdraw from issue after payment of the</strong><br /> issue fee (37 CFR 1.313(c)(1) or (2))</p>


                </div>

            </section>
        </Layout>
    )
};

export default SetUpAnAccount;